export const gen_augmints = [ 	
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Background",
		"name": "Stonks",
		"file": "Stonks.gif"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Background",
		"name": "Rolling Hills",
		"file": "Rolling Hills.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Background",
		"name": "Spring Sky",
		"file": "Spring Sky.gif"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Hawaiian Shirt",
		"file": "Hawaiian Shirt.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Hat",
		"name": "Hawaiian Flower",
		"file": "Hawaiian Flower.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Hat",
		"name": "Grass Crown",
		"file": "Grass Crown.gif"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Down Jacket",
		"file": "Down Jacket.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Orange Raincoat",
		"file": "Orange Raincoat.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Black Raincoat",
		"file": "Black Raincoat.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Black Chore Jacket",
		"file": "Black Chore Jacket.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Cream Chore Jacket",
		"file": "Cream Chore Jacket.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Hat",
		"name": "Cream Hat",
		"file": "Cream Hat.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Cream Hoodie",
		"file": "Cream Hoodie.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Purple Wizard Robe",
		"file": "Purple Wizard Robe.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Red Wizard Robe",
		"file": "Red Wizard Robe.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Blue Wizard Robe",
		"file": "Blue Wizard Robe.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Mouth",
		"name": "Yup",
		"file": "Yup.png"
	},
	{
		"isNew": 1,
        "isSelected":0,
        "id": 999,
		"type": "Mouth",
		"name": "Studded Grills",
		"file": "Studded Grills.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Mouth",
		"name": "Golden Grills",
		"file": "Golden Grills.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Mouth",
		"name": "Pizza",
		"file": "Pizza.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Color Blocked Hoodie",
		"file": "Color Blocked Hoodie.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Eyes",
		"name": "Aviator Goggles",
		"file": "Aviator Goggles.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Hat",
		"name": "Rubber Ducky",
		"file": "Rubber Ducky.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Eyes",
		"name": "Ivy Eyes",
		"file": "Ivy Eyes.gif"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Hat",
		"name": "Jolly Roger",
		"file": "Jolly Roger.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Hat",
		"name": "Pastel Egg",
		"file": "Pastel Egg.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Hat",
		"name": "Bunny Ears",
		"file": "Bunny Ears.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Mouth",
		"name": "Carrot Mouth",
		"file": "Carrot Mouth.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Bunny Onesie",
		"file": "Bunny Onesie.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Shark Suit",
		"file": "Shark Suit.png"
	},
	{
		"isSelected":0,
        "id": 1,
		"type": "Background",
		"name": "3333 Degreez",
		"file": "3333 Degreez.gif"
	},
	{
		"isSelected":0,
        "id": 24,
		"type": "Background",
		"name": "Northern Lights",
		"file": "Northern Lights.gif"
	},
	{
		"isSelected":0,
        "id": 22,
		"type": "Background",
		"name": "Ice Explosions",
		"file": "Ice Explosions.gif"
	},
	{
		"isSelected":0,
        "id": 27,
		"type": "Background",
		"name": "Snowy Mountains",
		"file": "Snowy Mountains.png"
	},
	{
		"isSelected":0,
        "id": 2,
		"type": "Body",
		"name": "Alien Skin",
		"file": "Alien Skin.png"
	},
	{
		"isSelected":0,
        "id": 12,
		"type": "Spikes",
		"name": "Black Spikes",
		"file": "Black Spikes.png"
	},

	{
		"isSelected":0,
        "id": 21,
		"type": "Spikes",
		"name": "Ice Spikes",
		"file": "Ice Spikes.gif"
	},

	{
		"isSelected":0,
        "id": 13,
		"type": "Spikes",
		"name": "Sand Spikes",
		"file": "Sand Spikes.png"
	},
	{
		"isSelected":0,
        "id": 11,
		"type": "Hat",
		"name": "Black Bucket Hat",
		"file": "Black Bucket Hat.png",
	},
	{
		"isSelected":0,
        "id": 3,
		"name": "Volcanic Spikes",
		"type": "Spikes",
		"file": "Volcanic Spikes.gif"
	},
	{
		"isSelected":0,
        "id": 44,
		"type": "Neck",
		"name": "Amber Winter Hoodie",
		"file": "Amber Winter Hoodie.png"
	},
	{
		"isSelected":0,
        "id": 0,
		"type": "Neck",
		"name": "Business Attire",
		"file": "Business Attire.png"
	},
	{
		"isSelected":0,
        "id": 41,
		"type": "Neck",
		"name": "Earthtone Plaid Scarf",
		"file": "Earthtone Plaid Scarf.png"
	},
	{
		"isSelected":0,
        "id": 42,
		"type": "Neck",
		"name": "Indigo Scarf",
		"file": "Indigo Scarf.png"
	},
	{
		"isSelected":0,
        "id": 16,
		"type": "Neck",
		"name": "Jean Jacket",
		"file": "Jean Jacket.png"
	},
	{
		"isSelected":0,
        "id": 32,
		"type": "Neck",
		"name": "Neon Green Sherpa",
		"file": "Neon Green Sherpa.png"
	},
	{
		"isSelected":0,
        "id": 33,
		"type": "Neck",
		"name": "Neon Orange Camo Sherpa",
		"file": "Neon Orange Camo Sherpa.png"
	},
	{
		"isSelected":0,
        "id": 9,
		"type": "Neck",
		"name": "New Money Chains",
		"file": "New Money Chains.png"
	},
	{
		"isSelected":0,
        "id": 43,
		"type": "Neck",
		"name": "Schemer Turtleneck",
		"file": "Schemer Turtleneck.png"
	},
	{
		"isSelected":0,
        "id": 17,
		"type": "Neck",
		"name": "Black Rugby",
		"file": "Black Rugby.png"
	},
	{
		"isSelected":0,
        "id": 18,
		"type": "Neck",
		"name": "Snow Camo Streetwear Hoodie",
		"file": "Snow Camo Streetwear Hoodie.png"
	},
	{
		"isSelected":0,
        "id": 23,
		"type": "Eyes",
		"name": "Frosted Eyes",
		"file": "Frosted Eyes.gif"
	},
	{
		"isSelected":0,
        "id": 4,
		"type": "Eyes",
		"name": "Hypnotic Specs",
		"file": "Hypnotic Specs.gif"
	},
	{
		"isSelected":0,
        "id": 36,
		"type": "Eyes",
		"name": "Onyx Goggles",
		"file": "Onyx Goggles.png"
	},
	{
		"isSelected":0,
        "id": 37,
		"type": "Eyes",
		"name": "Prismatic Goggles",
		"file": "Prismatic Goggles.png"
	},
	{
		"isSelected":0,
        "id": 38,
		"type": "Eyes",
		"name": "Ruby Goggles",
		"file": "Ruby Goggles.png"
	},
	{
		"isSelected":0,
        "id": 7,
		"type": "Hat",
		"name": "Bright Ski Mask",
		"file": "Bright Ski Mask.png"
	},
	{
		"isSelected":0,
        "id": 28,
		"type": "Hat",
		"name": "Chill Baby Kaiju",
		"file": "Chill Baby Kaiju.png"
	},
	{
		"isSelected":0,
        "id": 29,
		"type": "Hat",
		"name": "Cranky Baby Kaiju",
		"file": "Cranky Baby Kaiju.png"
	},
	{
		"isSelected":0,
        "id": 30,
		"type": "Hat",
		"name": "Happy Baby Kaiju",
		"file": "Happy Baby Kaiju.png"
	},
	{
		"isSelected":0,
        "id": 35,
		"type": "Hat",
		"name": "Earmuffs",
		"file": "Earmuffs.png",
	},
	{
		"isSelected":0,
        "id": 25,
		"type": "Hat",
		"name": "Ice Crown",
		"file": "Ice Crown.png"
	},
	{
		"isSelected":0,
        "id": 39,
		"type": "Hat",
		"name": "Neon Green Beanie",
		"file": "Neon Green Beanie.png"
	},
	{
		"isSelected":0,
        "id": 40,
		"type": "Hat",
		"name": "Neon Orange Camo Beanie",
		"file": "Neon Orange Camo Beanie.png"
	},
	{
		"isSelected":0,
        "id": 8,
		"type": "Hat",
		"name": "Ominous Ski Mask",
		"file": "Ominous Ski Mask.png"
	},
	{
		"isSelected":0,
        "id": 10,
		"type": "Hat",
		"name": "Rappa Beanie",
		"file": "Rappa Beanie.png"
	},
	{
		"isSelected":0,
        "id": 31,
		"type": "Hat",
		"name": "Sleeper Baby Kaiju",
		"file": "Sleeper Baby Kaiju.png"
	},
	{
		"isSelected":0,
        "id": 34,
		"type": "Hat",
		"name": "Snowman on Head",
		"file": "Snowman on Head.png"
	},
	{
		"isSelected":0,
        "id": 26,
		"type": "Augmint",
		"name": "Snow Fall",
		"file": "Snow Fall.gif"
	},
	{
		"isSelected":0,
        "id": 46,
		"type": "Neck",
		"name": "Golden Lab Coat",
		"file": "Golden Lab Coat.gif",
	},
	{
		"isSelected":0,
        "id": 45,
		"type": "Neck",
		"name": "Quill and Scroll",
		"file": "Quill and Scroll.png",
	},
]

export const baby_augmints = [
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Background",
		"name": "Stonks",
		"file": "Stonks.gif"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Background",
		"name": "Rolling Hills",
		"file": "Rolling Hills.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Background",
		"name": "Spring Sky",
		"file": "Spring Sky.gif"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Hawaiian Shirt",
		"file": "Hawaiian Shirt.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Hat",
		"name": "Hawaiian Flower",
		"file": "Hawaiian Flower.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Mouth",
		"name": "Pizza",
		"file": "Pizza.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Purple Wizard Robe",
		"file": "Purple Wizard Robe.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Red Wizard Robe",
		"file": "Red Wizard Robe.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Blue Wizard Robe",
		"file": "Blue Wizard Robe.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Down Jacket",
		"file": "Down Jacket.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Orange Raincoat",
		"file": "Orange Raincoat.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Black Raincoat",
		"file": "Black Raincoat.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Black Chore Jacket",
		"file": "Black Chore Jacket.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Cream Chore Jacket",
		"file": "Cream Chore Jacket.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Hat",
		"name": "Cream Hat",
		"file": "Cream Hat.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Cream Hoodie",
		"file": "Cream Hoodie.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Mouth",
		"name": "Yup",
		"file": "Yup.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Mouth",
		"name": "Studded Grills",
		"file": "Studded Grills.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Mouth",
		"name": "Golden Grills",
		"file": "Golden Grills.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Color Blocked Hoodie",
		"file": "Color Blocked Hoodie.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Eyes",
		"name": "Aviator Goggles",
		"file": "Aviator Goggles.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Hat",
		"name": "Rubber Ducky",
		"file": "Rubber Ducky.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Eyes",
		"name": "Ivy Eyes",
		"file": "Ivy Eyes.gif"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Hat",
		"name": "Jolly Roger",
		"file": "Jolly Roger.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Hat",
		"name": "Pastel Egg",
		"file": "Pastel Egg.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Hat",
		"name": "Bunny Ears",
		"file": "Bunny Ears.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Mouth",
		"name": "Carrot Mouth",
		"file": "Carrot Mouth.png"
	},
	{
		"isNew": 1,
		"isSelected":0,
        "id": 999,
		"type": "Neck",
		"name": "Bunny Onesie",
		"file": "Bunny Onesie.png"
	},
	{
		"isSelected":0,
        "id": 27,
		"type": "Background",
		"name": "Snowy Mountains",
		"file": "Snowy Mountains.png",
	},
	{
		"isSelected":0,
        "id": 2,
		"type": "Body",
		"name": "Alien Skin",
		"file": "Alien Skin.png",
	},
	{
		"isSelected":0,
        "id": 5,
		"type": "Spikes",
		"name": "Alien Spikes",
		"file": "Alien Spikes.png",
	},
	{
		"isSelected":0,
        "id": 44,
		"type": "Neck",
		"name": "Amber Winter Hoodie",
		"file": "Amber Winter Hoodie.png",
	},
	{
		"isSelected":0,
        "id": 0,
		"type": "Neck",
		"name": "Business Attire",
		"file": "Business Attire.png",
	},
	{
		"isSelected":0,
        "id": 41,
		"type": "Neck",
		"name": "Earthtone Plaid Scarf",
		"file": "Earthtone Plaid Scarf.png",
	},
	{
		"isSelected":0,
        "id": 42,
		"type": "Neck",
		"name": "Indigo Scarf",
		"file": "Indigo Scarf.png",
	},
	{
		"isSelected":0,
        "id": 16,
		"type": "Neck",
		"name": "Jean Jacket",
		"file": "Jean Jacket.png",
	},
	{
		"isSelected":0,
        "id": 32,
		"type": "Neck",
		"name": "Neon Green Sherpa",
		"file": "Neon Green Sherpa.png",
	},
	{
		"isSelected":0,
        "id": 33,
		"type": "Neck",
		"name": "Neon Orange Camo Sherpa",
		"file": "Neon Orange Camo Sherpa.png",
	},
	{
		"isSelected":0,
        "id": 9,
		"type": "Neck",
		"name": "New Money Chains",
		"file": "New Money Chains.png",
	},
	{
		"isSelected":0,
        "id": 43,
		"type": "Neck",
		"name": "Schemer Turtleneck",
		"file": "Schemer Turtleneck.png",
	},
	{
		"isSelected":0,
        "id": 15,
		"type": "Eyes",
		"name": "Boujee Specs",
		"file": "Boujee Specs.png",
	},
	{
		"isSelected":0,
        "id": 23,
		"type": "Eyes",
		"name": "Frosted Eyes",
		"file": "Frosted Eyes.gif",
	},
	{
		"isSelected":0,
        "id": 36,
		"type": "Eyes",
		"name": "Onyx Goggles",
		"file": "Onyx Goggles.png",
	},
	{
		"isSelected":0,
        "id": 37,
		"type": "Eyes",
		"name": "Prismatic Goggles",
		"file": "Prismatic Goggles.png",
	},
	{
		"isSelected":0,
        "id": 14,
		"type": "Eyes",
		"name": "Rocketeer Specs",
		"file": "Rocketeer Specs.png",
	},
	{
		"isSelected":0,
        "id": 38,
		"type": "Eyes",
		"name": "Ruby Goggles",
		"file": "Ruby Goggles.png",
	},
	{
		"isSelected":0,
        "id": 28,
		"type": "Hat",
		"name": "Chill Baby Kaiju",
		"file": "Chill Baby Kaiju.png",
	},
	{
		"isSelected":0,
        "id": 29,
		"type": "Hat",
		"name": "Cranky Baby Kaiju",
		"file": "Cranky Baby Kaiju.png",
	},
	{
		"isSelected":0,
        "id": 30,
		"type": "Hat",
		"name": "Happy Baby Kaiju",
		"file": "Happy Baby Kaiju.png",
	},
	{
		"isSelected":0,
        "id": 39,
		"type": "Hats",
		"name": "Neon Green Beanie",
		"file": "Neon Green Beanie.png",
	},
	{
		"isSelected":0,
        "id": 40,
		"type": "Hat",
		"name": "Neon Orange Camo Beanie",
		"file": "Neon Orange Camo Beanie.png",
	},
	{
		"isSelected":0,
        "id": 10,
		"type": "Hat",
		"name": "Rappa Beanie",
		"file": "Rappa Beanie.png",
	},
	{
		"isSelected":0,
        "id": 35,
		"type": "Hat",
		"name": "Earmuffs",
		"file": "Earmuffs.png",
	},
	{
		"isSelected":0,
        "id": 31,
		"type": "Hat",
		"name": "Sleeper Baby Kaiju",
		"file": "Sleeper Baby Kaiju.png",
	},
	{
		"isSelected":0,
        "id": 11,
		"type": "Hat",
		"name": "Black Bucket Hat",
		"file": "Black Bucket Hat.png",
	},
	{
		"isSelected":0,
        "id": 34,
		"type": "Hat",
		"name": "Snowman On Head (1)",
		"file": "Snowman On Head (1).png",
	},
	{
		"isSelected":0,
        "id": 26,
		"type": "Augmint",
		"name": "Snow Fall",
		"file": "Snow Fall.gif",
	},
	{
		"isSelected":0,
        "id": 6,
		"type": "Augmint",
		"name": "Star Eater",
		"file": "Star Eater.gif",
	},
	{
		"isSelected":0,
        "id": 46,
		"type": "Neck",
		"name": "Golden Lab Coat",
		"file": "Golden Lab Coat.gif",
	},
	{
		"isSelected":0,
		"id": 45,
		"type": "Neck",
		"name": "Quill and Scroll",
		"file": "Quill and Scroll.png",
	},
]